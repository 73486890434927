<template>
    <section>
        <div class="retailer">
            <div class="setupLeftColumn">
                <div class="aselect">
                    <h3 class="noPaddingH3">Select a Retailer</h3>
                    <div class="selector" @click="dropdowns.retailers = !dropdowns.retailers">
                        <div class="label">
                            <span>{{ retailerLabel }}</span>
                        </div>
                        <div class="arrow" :class="{ expanded: dropdowns.retailers }"></div>
                        <div :class="{ hidden: !dropdowns.retailers }">
                            <ul>
                                <li :class="{ current: item.soldto === (config.retailer || {}).soldto }" v-for="item in retailersList" :key="item.id" @click="setRetailer(item)">
                                    {{ item.soldto }} - {{ item.soldto_id }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="aselect" v-if="config.retailer && !dropdowns.retailers && !dropdowns.shipTo">
                    <h3 class="noPaddingH3">Select a Category (SELECT ONE)</h3>
                    <div class="table_summary">
                            <table class="table my-0 table__scroll-horizontal">
                                <tbody>
                                    <tr v-for="l1 in level1Categories" :key="l1.id" class="sub-rows">
                                        <th scope="row">
                                                <div class="row">
                                                    <td class="bg-lightblue font-bold row-style" v-for="l2 in level2Categories" :key="l2.id">
                                                        {{ l2.value }}
                                                    
                                                        <th scope="row">
                                                            <tr v-for="item in level3Categories" :key="item.level1_bth_or_holiday_id + item.level2_main_description_id + item.id">
                                                                    <div  v-if="item.level1_bth_or_holiday_id === l1.value && item.level2_main_description_id === l2.value">
                                                                        <button 
                                                                            class="btn button-sm" 
                                                                            :pressed="true" 
                                                                            variant="link" 
                                                                            @click="setProduct(item, l1.value, l2.value)"
                                                                            :style="{ color: hasSavedValues(item.category_value) ? '#009ACD' : '' }"
                                                                        >
                                                                            {{ item.display_name }}
                                                                        </button>
                                                                        <span style="color: #009ACD;" title="Saved planning data already exists for this product type." v-if="hasSavedValues(item.category_value)">&#x2713;</span>
                                                                    </div>
                                                            </tr>
                                                        </th>
                                                    </td>
                                                </div>
                                        </th>
                                    </tr>
                                </tbody>
                            </table> 
                    </div>
                </div>
            </div>
            <div class="setupRightColumn">
                    <div class="planningInformationDiv">
                        <h3><b>You are planning for:</b></h3>
                        <h4><b>Season:</b> {{ selectedL1 }}</h4>
                        <br/>
                        <h4><b>Category:</b> {{ selectedL2 }}</h4>
                        <br/>
                        <h4><b>Product Type:</b> {{ selectedProductDisplayName}}</h4>
                        <br/>
                        <h4><b>Time Frame:</b> April {{ bookingYear }} - March {{ bookingYear + 1 }}</h4>
                    </div>

                    <div class="startPlanningBtnDiv">
                        <button :disabled="!readyForNext" class="startPlanningBtn btn button-primary" @click="setConfig">
                            Start Planning
                        </button>
                        <button :disabled="bauerbizActive" class="startPlanningBtn btn button-primary" @click="bauerBizNavigation">
                            Upload orders to Bauer biz
                        </button>
                    </div>
                    <small style="text-align: center;" v-if="showDeadlinesImage">Click image below for booking deadline calendar (click again to close image)</small>
                    <div class="imageContainer" :class="{ 'centered': !isImageExpanded }" v-if="showDeadlinesImage">
                        <div class="imageOverlay" v-show="isImageExpanded" @click="toggleImage">
                            <img class="chartsImg" :src="`${baseUrl}booking_deadlines.JPG`" alt="Deadlines Chart">
                        </div>
                        <img class="chartsImg smallImage" :src="`${baseUrl}booking_deadlines.JPG`" alt="Deadlines Chart" @click="toggleImage">
                    </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { API } from 'aws-amplify';

import { getRetailers, getAllRetailers, getRetailerData, getShipToData, getLevelOneCategories, getLevelTwoCategories, getAllProducts, getCustomMaterialAllocationsBySoldtoId, getMonthlyTotalsBySoldtoId, getSegmentsFromProduct } from '@/graphql/queries';
import { getCatalogId , getFirstDimensions , getSecondDimensions } from '../../graphql/queries';

export default {
    data() {
        return {
            isImageExpanded: false,
            selectedL1: "",
            selectedL2: "",
            level1Categories: [],
            level2Categories: [],
            level3Categories: [],
            selectedProductDisplayName: "",
            dropdowns: {
                retailers: false,
                shipTo: false,
                products: false,
                planningMonths: false,
            },
            retailersList: [],
            shipToList: [],
            planningMonths: {
                '12 Months': [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
                'Back to Hockey': [4, 5, 6, 7, 8, 9, 10, 11],
            },
            config: {
                season: {
                    label: this.currentSeason,
                    months: [],
                    startDate: '2021-01-01',
                    endDate: '2022-12-31',
                },
                retailer: null,
                shipTo: null,
                product: null,
            },
            start: null,
            end: null,
            categoriesWithSavedValues: []
        };
    },
    computed: {
        ...mapGetters(['user', 'products', 'bookingYear', 'suggestedBookings', 'level1_bth_or_holiday', 'level2_main_description', 'top_planning_inputs', 'currentSeason', 'currentSeasonMutation' , 'season']),
        retailerLabel() {
            return this.config.retailer ? `${this.config.retailer.soldto} - ${this.config.retailer.soldto_id}` : 'Select Retailer';
        },
        baseUrl() {
        return process.env.BASE_URL;
        },
        shipToLabel() {
            return this.config.shipTo ? `${this.config.shipTo.ship_to_name} - ${this.config.shipTo.ship_to_id}` : 'Select ShipTo';
        },
        productLabel() {
            return this.config.product ? this.config.product.name : 'Select Product';
        },
        planningMonthsLabel() {
            return this.config.season.months.length === 12 ? '12 Months' : 'Back to Hockey';
        },
        readyForNext() {
            return !!this.config.retailer && !!this.config.product && !!this.config.season.months;
        },
        bauerbizActive() {
            return this.config.retailer  ? false  : true ;
        },
        bookingMonths() {
            const months = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];
            const start = this.start.getMonth() + 1;
            const end = this.end.getMonth() + 1;
            return months.slice(months.indexOf(start), months.indexOf(end) + 1);
        },
        showDeadlinesImage(){
            if(this.config.retailer != null){
                if(this.config.retailer.sorg == 'CA10' || this.config.retailer.sorg == 'US10'){
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        }
    },
    methods: {
        toggleImage() {
            this.isImageExpanded = !this.isImageExpanded;
        },
        hasSavedValues(category){
            return this.categoriesWithSavedValues.includes(category);
        },
        getData(data, filterObj) {
            return find(data, filterObj) || 0;
        },
        
        checkCategory(level1_bth_or_holiday_id, level2_main_description_id) {
            let data = this.getData(this.products, {level1_bth_or_holiday_id: level1_bth_or_holiday_id, level2_main_description_id: level2_main_description_id});
            return (data.length > 0);
        },
        async getRetailersList() {
            if (this.user.admin) {
                const result = await API.graphql({
                    query: getAllRetailers,
                });
                this.retailersList = result.data.getAllRetailers.filter(item => String(item.soldto_id).length !== 6);
            } else {
                const result = await API.graphql({
                    query: getRetailers,
                    variables: { rep_id: this.user.salesRepId },
                });
                this.retailersList = result.data.getRetailers;
            }
        },
        async getDimensions(){
            const dim1Result = await API.graphql({ query : getFirstDimensions});
            let dim1Obj=  {};
            let dim2Obj = {};
            dim1Result.data.getFirstDimensions.forEach((item) => {
                dim1Obj = {...dim1Obj , [item['dim1']] : item['order_num'] };
            });
            const dim2Result = await API.graphql({ query : getSecondDimensions});
            dim2Result.data.getSecondDimensions.forEach((item) => {
                dim2Obj = {...dim2Obj , [item['dim2']] : item['order_num'] };
            });
            this.$store.commit("setFirstDimenionData", dim1Obj);
            this.$store.commit("setSecondDimenionData", dim2Obj);
        },
        async getRetailerData() {
            const result = await API.graphql({
                query: getRetailerData,
                variables: { soldto_id: this.config.retailer.soldto_id },
            });
            const data = result.data.getRetailerData[0];
            this.config.retailer = data;
        },
        async getShipToData() {
            const result = await API.graphql({
                query: getShipToData,
                variables: { sold_to_id: this.config.retailer.soldto_id },
            });
            let data = result.data.getShipToData || [];

            if (data.length > 0) {
                data.unshift({
                    ship_to_id: this.config.retailer.soldto_id,
                    ship_to_name: 'SOLD TO',
                });
            }

            this.shipToList = data;
        },
        async loadCategories() {
            const result = await API.graphql({
                query: getAllProducts
            });

            this.$store.commit('setProducts', result.data.getAllProducts);

            result.data.getAllProducts.forEach(category => {
                const existsAlready = this.level3Categories.find(x => x['level1_bth_or_holiday_id'] == category['level1_bth_or_holiday_id'] && x['level2_main_description_id'] == category['level2_main_description_id'] && x.id == category.id);
                if (existsAlready == null) {
                    // category['segments'] = ['Elite', 'Performance', 'Recreational'];
                    category['families'] = {
                        launch: {
                            families: ['bauer', 'vapor'],
                            elite: ['vapor'],
                            performance: ['vapor'],
                            recreational: ['bauer'],
                            isActive: false,
                        },
                        migrating: {
                            families: ['bauer', 'supreme'],
                            elite: ['supreme'],
                            performance: ['supreme'],
                            recreational: ['bauer'],
                            isActive: false,
                        }
                    };
                    this.level3Categories.push(category);
                }
            });

            const result2 = await API.graphql({
                query: getLevelOneCategories
            });

            this.level1Categories = result2.data.getLevelOneCategories;

            const result3 = await API.graphql({
                query: getLevelTwoCategories
            });

            this.level2Categories = result3.data.getLevelTwoCategories;
        },
        async setRetailer(obj) {
            if (this.config.retailer?.soldto_id !== obj.soldto_id) {
                this.config.retailer = obj;
                this.config.shipTo = null;
                this.config.product = null;

                this.getShipToData();

                this.getRetailerData(obj.soldto_id);
                this.categoriesWithSavedValues = [];
                const response = await API.graphql({
                    query: getCustomMaterialAllocationsBySoldtoId,
                    variables: { 'soldto_id': obj.soldto_id, 'year': this.season.year },
                });

                if(response.data.getCustomMaterialAllocationsBySoldtoId != null && response.data.getCustomMaterialAllocationsBySoldtoId.length > 0){
                    response.data.getCustomMaterialAllocationsBySoldtoId.forEach(savedElement => {
                        this.categoriesWithSavedValues.push(savedElement.category);
                    });
                }

                const savedMontlyData = await API.graphql({
                        query: getMonthlyTotalsBySoldtoId,
                        variables: { soldto_id: obj.soldto_id, season: this.currentSeasonMutation },
                });
                
                if(savedMontlyData.data.getMonthlyTotalsBySoldtoId != null && savedMontlyData.data.getMonthlyTotalsBySoldtoId.length > 0){
                    savedMontlyData.data.getMonthlyTotalsBySoldtoId.forEach(savedElement => {
                        this.categoriesWithSavedValues.push(savedElement.category);
                    });
                }

            }
        },
        setShipTo(obj) {
            this.config.shipTo = obj;
        },
        async setProduct(obj, l1, l2) {
            this.selectedL1 = l1;
            this.selectedL2 = l2;
            this.selectedProductDisplayName = obj['display_name'];
            if(obj['top_planning_inputs_id'] == 'PGS'){
                const result = await API.graphql({
                    query: getSegmentsFromProduct,
                    variables: { group_desc: obj['group_description'], season: obj['level1_bth_or_holiday_id'], category: obj.category_value},
                });
                this.config.product = obj;
                this.config.product['segments'] = [];
                result.data.getSegmentsFromProduct.forEach(segment => {
                    this.config.product['segments'].push(segment['pgs']);
                });
            }else{
                this.config.product = obj;
                this.config.product['segments'] = [];
                this.config.product['segments'] = ['Elite', 'Performance', 'Recreational'];
            }
        },
        setPlanningMonths(arr) {
            this.config.season.months = arr;
        },
        async setConfig() {
            this.config.season.months = this.bookingMonths;
            this.$store.commit('setPlanningConfig', this.config);
            this.$router.push('/planning/suggested');
            this.$root.$emit('updatedConfig');
        },
        async bauerBizNavigation(){
            this.config.season.months = this.bookingMonths;
            this.$store.commit('setPlanningConfig', this.config);
            const {data} = await API.graphql({
                    query: getCatalogId,
                    variables: { bauerhockey_class : this.config.retailer.bauerhockey_class , sales_org : this.config.retailer.sorg}
            });
            this.$store.commit('setCatalogId', data.getCatalogId.catalog.trim());
            this.$router.push('/planning/uploadtobb');
        }
    },
    created() {
        this.start = new Date(`${ this.bookingYear }/4/1`);
        this.end = new Date(`${ this.bookingYear + 1 }/3/31`);
        this.getRetailersList();
        this.loadCategories();
        this.getDimensions();
    },
};
</script>
<style>

.setupRightColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.centered {
    justify-content: center;
}

.chartsImg {
    width: 92%;
    height: auto;
}

.smallImage {
    max-height: 200px;
    cursor: zoom-in; /* Set cursor to zoom-in */
}

.imageContainer {
    display: flex;
    justify-content: center;
}

.imageOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    cursor: zoom-in; /* Set cursor to zoom-in */
}

.imageOverlay.expanded {
    opacity: 1;
    visibility: visible;
    cursor: zoom-out; /* Set cursor to zoom-out */
}

.imageOverlay.expanded .chartsImg {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.imageOverlay.expanded .chartsImg.smallImage {
    display: none;
}

.imageHoverOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    cursor: zoom-out; /* Set cursor to zoom-out */
}

.imageContainer:hover .imageOverlay {
    opacity: 1;
    visibility: visible;
    cursor: zoom-out; /* Set cursor to zoom-out */
}

.imageContainer:hover .imageHoverOverlay {
    opacity: 1;
    visibility: visible;
    cursor: zoom-out; /* Set cursor to zoom-out */
}


</style>